

































































































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { getComponent, getConfigEnv } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
// import setpointModule from '@/store/modules/setpointModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
// import { CHART_CLICK_EVENT } from '@/lib/constants';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import tasqActionsModule from '@/store/modules/tasqActionsModule';
import DateRangePicker from 'vue2-daterange-picker';
import { Debounce } from 'vue-debounce-decorator';
import { SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import DataLoading from '../../lib/mixins/dataLoading';
import TasqJob from '../../interfaces/tasqs/TasqJob';

@Component({
  components: {
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    LineChart: () => import('@/lib/charts/lineChart'),
    DateRangePicker,
    VueSlider,
  },
})
export default class DefermentLabeling extends mixins(DataLoading) {
  @Prop({ required: true }) tasq!: TasqJob;

	showTrainingDefermentModelProgress = false;

	isLoadingTrainingData = false

	isSavingModel = false

	isPreviewingModel = false

  fixedAxis = false;

  comment: any = '';

  mouseLeaveRange() {
    setTimeout(() => {
      this.fixedAxis = true;
    }, 1500);
  }

  customSignalRange: any = { startDate: null, endDate: null }

  yAxisRange: any =[0, 100];

  showWellTargetPopup = false;

   xAxisRange =['', ''];

   minYaxis: any = 0;

   zoomUpdated = 0;

   maxYaxis: any = 100;

   get selectedCustomRange() {
     if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
       return true;
     }
     return false;
   }

   get dateRangeCustomPreset() {
     const today = new Date();
     today.setHours(0, 0, 0, 0);
     return {
       'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
       'Last 3 months': [new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
       'Last 6 months': [new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
       'Last Year': [new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
     };
   }

   hideRemoveTargetPopup() {
     this.showWellTargetPopup = false;
   }

   async customDateUpdated(data) {
     let fromNowBack = 0;
     const dateNow: any = new Date();
     const date1 = data.startDate;
     const date2 = data.endDate;
     if (Math.ceil(Math.abs(date2)) < Math.ceil(Math.abs(dateNow))) {
       fromNowBack = Math.ceil(Math.abs(date2 - dateNow) / (1000 * 60 * 60 * 24));
     }
     tasqsListModule.setFromNowBack(fromNowBack);
     const diffTime = Math.abs(date2 - date1);
     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
     this.startDataLoading();
     await defermentLabelingModule.getChartReLabelingData(diffDays);
     await tasqProductionDataChartModule.getProductionDataDefermentLabeling(diffDays);
     await tasqProductionDataChartModule.getProductionData(diffDays);

     await this.sleep(500);
     await this.reloadChart();
     this.stopDataLoading();
   }

   get productionData() {
     return tasqProductionDataChartModule.productionData;
   }

   get productionDataDefermentLabeling() {
     return tasqProductionDataChartModule.productionDataDefermentLabeling;
   }

  productionDataDict: any = {}

  get defermentLabelingData() {
    return defermentLabelingModule.chartLabelingData;
  }

  get updatePredictionData() {
    return defermentLabelingModule.datasetForPrediction;
  }

  get modelUUID() {
	  return defermentLabelingModule.ModelUUID;
  }

  previewUpdate() {
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  formatXaxis(val) {
    return new Date(val).toLocaleString();
  }

  async animateDefermentLabelProgressBar(seconds) {
	  if (!seconds) {
		  seconds = 60;
	  }
	  this.showTrainingDefermentModelProgress = true;
	  const microseconds = (defermentLabelingModule.defermentModelCalculatingETA + 15) * 100;
	  const previous_eta = defermentLabelingModule.defermentModelCalculatingETA;
	  for (let x = 1; x < microseconds; x++) {
		  // @ts-ignore
      this.$refs.progressBarID.style.width = `${((x / microseconds) * 100).toString()}%`;
      // @ts-ignore
      this.$refs.progressBarStringID.innerHTML = `Training... ${Math.round((x / microseconds) * 100).toString()}%`;

      await this.sleep(1);
	  }
	  this.showTrainingDefermentModelProgress = false;
  }

  selectedDates = [] as any;

  chartData: any = {
    datasets: [
      {
        label: '',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'rgba(44,230,194,0.09)',
        tension: 0,
        fill: false,
        pointRadius: 5,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        pointBorderColor: '#2CE6C2',
        // pointHoverBackgroundColor: '#2CE6C2',
        // pointHoverBorderColor: '#2CE6C2',
        pointBackgroundColor: [] as any,
        borderWidth: 1.5,
        spanGaps: true,
      },
      {
        label: 'Target upper band',
        data: [],
        borderColor: 'rgba(44,230,194,0.1)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(44,230,194,0.25)',
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
	  {
        label: 'Target',
        data: [],
        borderColor: '#2CE6C2',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Preview upper band',
        data: [],
        borderColor: 'rgba(244,130,94,0.2)',
        tension: 0,
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Preview lower band',
        data: [],
        borderColor: 'transparent',
        backgroundColor: 'rgba(244,130,94,0.35)',
        tension: 0,
        borderWidth: 2,
        fill: '-1',
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
      {
        label: 'Target preview',
        data: [],
        borderColor: '#e37a4d',
        backgroundColor: 'transparent',
        tension: 0,
        borderWidth: 2,
        fill: true,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: 'y-axis-0',
        spanGaps: true,
        unit: '',
      },
    ],
    labels: [],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255,255,255,0.7)',
      },
    },
    animation: {
      duration: 500,
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#ffffff90',
            fontStyle: 'bold',
            maxTicksLimit: 8,
            minRotation: 50,
          },
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MM/DD',
            },
          },
        },
      ],
      yAxes: [
        {
          position: 'left',
          type: 'linear',
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 2,
            fontStyle: 'bold',
            min: 0,
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
    },
    onClick: (evt) => {
      const chart = (this.$refs.defermentChart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        const dateIndex = item[0]._index;
        const date = this.chartData.labels[dateIndex];
        this.toggleDateOnChart(date);
      }
    },
    annotation: {
      annotations: [],
    },
  };

  height = 700;

  async created() {
    try {
      defermentLabelingModule.setModelUUID(null);

      this.startDataLoading();
      if (!this.productionDataDefermentLabeling.loaded) {
        // await tasqProductionDataChartModule.getProductionData(730);
        await tasqProductionDataChartModule.getProductionDataDefermentLabeling(730);
        this.productionDataDict = tasqProductionDataChartModule.productionDataDict;
      }

      this.productionDataDict = tasqProductionDataChartModule.productionDataDict;

      this.getChartData().then(async () => {
        this.height = (window.innerHeight / 2) - 100;
        await this.reloadChart();
      });
    } catch (error:any) {
      this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, { text: 'It is not possible to retrain the model at this moment, please try later', error: true });
      console.log(error.message);
      this.dataLoading = false;
    }
  }

  async reloadChart() {
    let productionDataDeferment = this.productionDataDefermentLabeling.data
    if (getConfigEnv('OPERATOR_LOWERCASED') !== 'swn' && getConfigEnv('OPERATOR_LOWERCASED') !== 'caerus' && getConfigEnv('OPERATOR_LOWERCASED') !== 'pdc') {
      // eslint-disable-next-line prefer-destructuring
      this.chartData.datasets[0].data = productionDataDeferment && productionDataDeferment[0] ?  this.productionDataDefermentLabeling.data[0]: [];
      this.chartData.datasets[0].label = 'Oil rate';
      this.chartData.datasets[0].borderColor = '#2CE6C2';
      this.chartData.datasets[1].borderColor = 'rgba(44,230,194,0.1)'; // Target upper band
      this.chartData.datasets[2].borderColor = 'transparent'; // Target lower band
      this.chartData.datasets[2].backgroundColor = 'rgba(44,230,194,0.25)'; // Target lower band
      this.chartData.datasets[3].borderColor = '#2CE6C2'; // Dashed target band
    } else {
      // eslint-disable-next-line prefer-destructuring
      this.chartData.datasets[0].data = productionDataDeferment && productionDataDeferment[2] ? this.productionDataDefermentLabeling.data[2]: [];
      this.chartData.datasets[0].label = 'Gas rate';
      this.chartData.datasets[0].borderColor = '#f55d8b';
      this.chartData.datasets[1].borderColor = 'rgba(245, 93, 139,0.1)'; // Target upper band
      this.chartData.datasets[2].borderColor = 'transparent'; // Target lower band
      this.chartData.datasets[2].backgroundColor = 'rgba(245, 93, 139,0.25)'; // Target lower band
      this.chartData.datasets[3].borderColor = '#f55d8b'; // Dashed target band
    }

    this.xAxisRange = [this.productionDataDefermentLabeling.time[0], this.productionDataDefermentLabeling.time[this.productionDataDefermentLabeling.time.length - 1]];

    this.chartData.datasets[1].data = this.productionDataDefermentLabeling.data[3];
    this.chartData.datasets[2].data = this.productionDataDefermentLabeling.data[5];
    this.chartData.datasets[3].data = this.productionDataDefermentLabeling.data[4];
    this.chartData.labels = this.productionDataDefermentLabeling.time;
    const mappedDataset = this.defermentLabelingData.dataset
      .filter((i) => i.Include)
      .map((i) => i.date);
    this.chartData.datasets[0].pointBackgroundColor = this.chartData.labels.map((i) => (mappedDataset.includes(i) ? '#2CE6C2' : 'transparent'));
    if (getConfigEnv('OPERATOR_LOWERCASED') !== 'swn' && getConfigEnv('OPERATOR_LOWERCASED') !== 'caerus' && getConfigEnv('OPERATOR_LOWERCASED') !== 'pdc') {
      this.chartData.datasets[1].data = this.productionDataDefermentLabeling.data[6];
      this.chartData.datasets[2].data = this.productionDataDefermentLabeling.data[8];
      this.chartData.datasets[3].data = this.productionDataDefermentLabeling.data[7];
    }

    const targetKey = this.chartData.datasets[0].label.includes('Gas') ? 'upperDataTime' : 'upperDataOilRateTime';

    if (targetKey && this.productionDataDefermentLabeling[targetKey] && this.productionDataDefermentLabeling[targetKey][0]) {
      const indexForTarget = this.productionDataDefermentLabeling.time.findIndex((t) => t === this.productionDataDefermentLabeling[targetKey][0]);
      if (indexForTarget > 10) {
        console.log('Fixed target');
        this.chartData.datasets[1].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[1].data);
        this.chartData.datasets[2].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[2].data);
        this.chartData.datasets[3].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[3].data);
      }
    }

    // @ts-ignore

    // @ts-ignore
    // eslint-disable-next-line radix
    const dataArray = (this.chartData.datasets[0].data.map((i) => Number(i)));
    const min = Math.min(...dataArray);
    const max = Math.max(...dataArray);

    // eslint-disable-next-line radix
    this.minYaxis = Number(min.toFixed(0));

    // eslint-disable-next-line radix
    this.maxYaxis = Number((max).toFixed(0)) + 300;

    this.yAxisRange = [this.minYaxis, this.maxYaxis];

    // this.yAxisRange = [this.minYaxis, this.maxYaxis];
    this.enableDragging();

    this.stopDataLoading();
  }

  async getChartData() {
    await defermentLabelingModule.getChartReLabelingData();
  }

  async saveDefermentCalc() {
	  // this.isSavingModel = true;
    this.$emit('update-prediction-finished', this.comment);
	  await defermentLabelingModule.saveFitRun({
      ModelUUID: defermentLabelingModule.ModelUUID,
    });
    // this.isSavingModel = false;
  }

  async removeTarget() {
	  // this.isSavingModel = true;
    this.showWellTargetPopup = false;
    this.$emit('update-prediction-finished', this.comment);
	  await defermentLabelingModule.saveFitRunAndRemoveTarget({
      ModelUUID: '',
      nodeID: this.tasq.wellName,
      valueType: this.tasq.defermentType,
      nullValue: true,
    });
    // this.isSavingModel = false;
  }

  async runDefermentCalc() {
	  this.isPreviewingModel = true;
    const mappedMasks = this.selectedDates.map((i) => ({
      date: i,
      Include: true,
    }));
    const mappedData: any[] = [];
    // console.log(this.productionDataDict);
    if (this.tasq.defermentType == 'gas_rate') {
      for (var f = 0; f < this.productionDataDict.date.length; f++) {
        mappedData.push({
          date: this.productionDataDict.date[f],
          gas_rate: parseFloat(this.productionDataDict.gas_rate[f]),
        });
      }
    } else if (this.tasq.defermentType == 'oil_rate' && this.productionDataDict && this.productionDataDict.date) {
      for (var f = 0; f < this.productionDataDict.date.length; f++) {
        mappedData.push({
          date: this.productionDataDict.date[f],
          oil_rate: parseFloat(this.productionDataDict.oil_rate[f]),
        });
      }
    }

	  await defermentLabelingModule.getFitRun({
      data: mappedData,
      nodeid: this.tasq.wellName,
      value_type: this.tasq.defermentType,
      mask: mappedMasks,
    });
    // await this.sleep(10000)
    while (defermentLabelingModule.fetchingDefermentLabelPrediction) {
      await this.sleep(500);
    }
    this.addNewDefermentLabelsToChart();
    setTimeout(() => {
      this.enableDragging();
    }, 1000);
    this.isPreviewingModel = false;
  }

  async addNewDefermentLabelsToChart() {
	  this.startDataLoading();
	  await this.sleep(1);
	  let fillUpToIndex = 0;
	  for (let y = 0; y < this.chartData.labels.length; y++) {
		  if (this.chartData.labels[y] == defermentLabelingModule.newChartLabelingData[0].date) {
			  fillUpToIndex = y;
			  break;
		  } else {
		  }
	  }
	  const newDataUpperThreshold: any[] = [];
	  const newDataLowerThreshold: any[] = [];
	  const newDataReferenceData: any[] = [];
	  for (let r = 0; r < fillUpToIndex; r++) {
		  newDataUpperThreshold.push(null);
		  newDataLowerThreshold.push(null);
		  newDataReferenceData.push(null);
	  }
	  for (let x = 0; x < defermentLabelingModule.newChartLabelingData.length; x++) {
		  newDataReferenceData.push(defermentLabelingModule.newChartLabelingData[x].reference);
		  newDataLowerThreshold.push(defermentLabelingModule.newChartLabelingData[x].lower_thresh);
		  newDataUpperThreshold.push(defermentLabelingModule.newChartLabelingData[x].upper_thresh);
	  }
    // @ts-ignore
	  this.chartData.datasets[6].data = newDataReferenceData;
    // @ts-ignore
	  this.chartData.datasets[4].data = newDataUpperThreshold;
	  // @ts-ignore
	  this.chartData.datasets[5].data = newDataLowerThreshold;

	  this.stopDataLoading();
  }

  async updatePrediction() {
    this.startDataLoading();
    const mappedLabels = this.selectedDates.map((i) => ({
      date: i,
      OperatorNodeID: `${getConfigEnv('OPERATOR_PASCALED')}##${this.tasq.wellName}`,
      Include: true,
      key: `${getConfigEnv('OPERATOR_PASCALED')}##${this.tasq.wellName}##${this.tasq.defermentType}`,
      Operator: getConfigEnv('OPERATOR_PASCALED'),
      ModifiedBy: accountModule.user.email,
    }));
    await defermentLabelingModule.updatePrediction({
      mappedLabels,
    });

    this.stopDataLoading();
    this.$emit('update-prediction-finished', this.comment);
    this.comment = '';
  }

  toggleDateOnChart(date, skipRemoving = false) {
    // @ts-ignore
    const dateIndex = this.chartData.labels.indexOf(date);
    // @ts-ignore
    const foundIndex = this.selectedDates.indexOf(date);
    if (foundIndex !== -1 && !skipRemoving) {
      this.selectedDates.splice(foundIndex, 1);
      this.chartData.datasets[0].pointBackgroundColor[dateIndex] = 'transparent';
    } else {
      if (!skipRemoving || foundIndex === -1) {
        // @ts-ignore
        this.selectedDates.push(date);
      }
      this.chartData.datasets[0].pointBackgroundColor[dateIndex] = '#2CE6C2';
    }
    (this.$refs.defermentChart as any).$data._chart.update();
    // this.runDefermentCalc()
  }

  @Debounce(500)
  enableDragging() {
    // @ts-ignore

    const { canvas } = this.$refs.defermentChart.$data._chart;

    // canvas.parentNode.replaceChild(canvas.cloneNode(true), canvas);

    canvas.removeEventListener('pointerdown', null);
    canvas.removeEventListener('pointerup', null);
    canvas.removeEventListener('pointerout', null);

    // @ts-ignore
    const chart = this.$refs.defermentChart.$data._chart;
    const overlay = document.getElementById('overlay-deferment-labeling');
    if (!overlay) return;
    const { borderColor } = chart?.options?.annotation?.annotations[0] || {};
    let startIndex = -1;
    // @ts-ignore
    overlay.width = window.innerWidth;
    // // @ts-ignore
    // overlay.height = (window.innerHeight / 2) - 100;
    // @ts-ignore
    const selectionContext = overlay.getContext('2d');
    const selectionRect = {
      w: 0,
      startX: 0,
      startY: 0,
    };
    selectionContext.fillStyle = borderColor || 'rgba(255, 255, 255)';
    let drag = false;
    canvas.addEventListener('pointerdown', (evt) => {
      const points = chart.getElementsAtEventForMode(evt, 'index', {
        intersect: false,
      });
      startIndex = points[0] ? points[0]._index : 0;
      const rect = canvas.getBoundingClientRect();
      selectionRect.startX = evt.clientX - rect.left;
      selectionRect.startY = chart.chartArea.top;
      drag = true;
    });
    canvas.addEventListener('pointermove', (evt) => {
      let rect = canvas.getBoundingClientRect();
      if (drag) {
        rect = canvas.getBoundingClientRect();
        // console.log( evt.clientX - rect.left - selectionRect.startX);
        selectionRect.w = evt.clientX - rect.left - selectionRect.startX;
        selectionContext.globalAlpha = 0.5;
        selectionContext.fillStyle = borderColor;
        selectionContext.clearRect(0, 0, window.innerWidth, (window.innerHeight / 2));
        selectionContext.fillRect(
          selectionRect.startX + 21,
          selectionRect.startY,
          selectionRect.w,
          chart.chartArea.bottom - chart.chartArea.top,
        );
      } else {
        selectionContext.clearRect(0, 0, window.innerWidth, (window.innerHeight / 2));
        const x = evt.clientX - rect.left;
        if (x > chart.chartArea.left) {
          selectionContext.fillRect(
            x + 21,
            chart.chartArea.top,
            1,
            chart.chartArea.bottom - chart.chartArea.top,
          );
        }
      }
    });

    canvas.addEventListener('pointerup', (evt) => {
      const points = chart.getElementsAtEventForMode(evt, 'index', {
        intersect: false,
      });

      drag = false;
      drag = false;
      console.log(startIndex);
      let start = startIndex;
      if (startIndex === -1) {
        return;
      }
      let end = points[0]._index;
      console.log(points);
      console.log(points[0]._index);
      // console.log(end);
      if (start > end) {
        end = this.zoomUpdated > 2 ? startIndex : startIndex;
        // console.log(points[0]._index);
        start = this.zoomUpdated > 2 ? points[0]._index : points[0]._index;
        // console.log(startIndex);
      } else {
        end = this.zoomUpdated > 3 ? points[0]._index : points[0]._index;
        // console.log(points[0]._index);
        start = this.zoomUpdated > 3 ? startIndex : startIndex;
      }
      if (Math.abs(start - end) >= 1) {
        end += 1;
      }

      console.log(start);
      console.log(end);
      console.log(this.zoomUpdated);

      const dates = this.chartData.labels.slice(start, end);
      dates.forEach((date) => this.toggleDateOnChart(date, true));
    });
    canvas.addEventListener('pointerout', () => {
      selectionContext.clearRect(0, 0, canvas.width, canvas.height);
    });
  }

  @Debounce(1000)
  @Watch('xAxisRange')
  updatexAxisRangeValue(value) {
    // this.signalVisible = true;
    this.zoomUpdated += 1;
    // console.log(value);
    Object.assign(this.chartOptions.scales.xAxes[0].ticks, {
      max: value[1],
      min: value[0],
    });

    setTimeout(() => {
      this.enableDragging();
    }, 1000);

    // @ts-ignore
  }

  @Debounce(1000)
  @Watch('yAxisRange')
  updateMaxRangeValue(value) {
    // this.signalVisible = true;
    this.zoomUpdated += 1;
    Object.assign(this.chartOptions.scales.yAxes[0].ticks, {
      max: value[1],
      min: value[0],
    });

    setTimeout(() => {
      this.enableDragging();
    }, 1000);
  }
}
